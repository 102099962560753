<template>
  <div class="estoque-body">
    <GenericTable
      ref="lista"
      name="instrumento"
      title="Instrumento"
      add-route="instrumentoAdicionar"
      edit-route="instrumentoEditar"
      :disabledTitle="true"
      :cols="[
        'Code',
        'Tipo de Instrumento',
        'Qtd de peças',
      ]"
      :cols-name="[
        'code',
        'descricao',
        'quantidade_pecas',
      ]"
      :cols-map="
        (i) => [
          i.code,
          i.descricao,
          i.quantidade_pecas,
        ]
      "
      route="item"
      :permissionsToWrite="['rw_material']"
      :filters="filtersLista"
      idKey="id_item"
      hasPagination
      :noadd="false"
    >
      <template #filters>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Tipo de Instrumento</label>
              <GenericSelectSearch
                route="tipoItem/listWithSearch"
                name="tipoItem"
                labelKey="descricao"
                :value="tipoItemSelect"
                idKey="id_tipo_item"
                @input="instrumentoSelect"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>QR Code</label>
              <b-form-input
                :value="filtersLista.code"
                autocomplete="off"
                class="invision-input sm"
                @input="v => updateFilters('code', v)"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericTable>
  </div>
</template>

<script>
import GenericSelectSearch from '@/components/Form/GenericSelectSearch';

export default {
  components: {
    GenericSelectSearch,
  },

  props: {
    filtersLista: {
      type: Object,
    },
    tipoItemSelect: {
      type: Object,
    },
  },

  data() {
    return {

    };
  },

  methods: {
    instrumentoSelect(itemSelected) {
      this.$emit('update-filters-lista', 'id_tipo_item', itemSelected ? itemSelected.id_tipo_item : null);
      this.$emit('update-tipo-item-select', itemSelected);
    },
    updateFilters(varName, v) {
      this.$emit('update-filters-lista', varName, v);
    },
  },
};
</script>
