<template>
  <div>
    <GenericTable
      ref="lista"
      title="Materiais"
      name="materiais"
      editRoute=""
      edit-route="materialEditar,materialMedicoEditar"
      rastreabilidadeRoute="materialRastrear"
      reposicaoRoute="reposicaoMaterial"
      :disabledTitle="true"
      :cols="[
        'ID',
        'Material',
        'Tipo',
        'Unidade',
        'Qtd peças',
        'Qtd faltantes',
      ]"
      :cols-name="[
        'id',
        'descricao',
        'tipo_material',
        'unidadePatrimonio',
        'quantidade_itens',
        'quantidade_atual_itens',
      ]"
      :cols-map="
        (i) => [
          i.id,
          i.descricao,
          i.tipoMaterial.nome,
          i.unidadePatrimonio.nome,
          i.quantidade_itens,
          (i.quantidade_itens - i.quantidade_atual_itens),
        ]
      "
      :route="materialRoute"
      :permissionsToWrite="['rw_material']"
      :filters="filters"
      @clear-filters="clearFilters"
      idKey="id_material"
      :hasPagination="true"
      :noadd="true"
    >
      <template #filters>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Descrição</label>
              <b-form-input
                v-model="filters.descricao"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>QR Code</label>
              <b-form-input
                v-model="filters.code"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>ID</label>
              <b-form-input
                v-model="filters.id_material"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Tipo de Material</label>
              <GenericSelect
                name="tipoMaterial"
                labelKey="nome"
                v-model="filters.id_tipo_material"
                route="tipoMaterial"
              ></GenericSelect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Unidade</label>
              <GenericSelect
                name="unidade"
                labelKey="nome"
                v-model="filters.id_unidade"
                route="unidade"
              ></GenericSelect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Setor</label>
              <GenericSelect
                name="setor"
                labelKey="nome"
                v-model="filters.id_setor"
                route="setor"
              ></GenericSelect>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericTable>
  </div>
</template>

<script>
import GenericSelect from '@/components/Form/GenericSelect';

export default {
  components: {
    GenericSelect,
  },
  data() {
    return {
      materialRoute: 'material/reposicao/list',
      filters: {
        descricao: '',
        code: '',
        id_material: '',
        id_tipo_material: null,
        id_unidade: null,
        id_setor: null,
      },
      noAdd: false,
    };
  },
  methods: {
    clearFilters() {
      this.filters.descricao = '';
      this.filters.code = '';
      this.filters.id_material = '';
      this.filters.id_tipo_material = null;
      this.filters.id_unidade = null;
    },
  },
};
</script>
